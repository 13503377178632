import {
	DefaultFallbackStrategy,
	DefaultInterceptor,
	DefaultMissingHandler,
	provideTranslocoConfig,
	provideTranslocoFallbackStrategy,
	provideTranslocoInterceptor,
	provideTranslocoMissingHandler,
	TRANSLOCO_SCOPE,
} from '@jsverse/transloco';
import { Provider } from '@angular/core';
import { translocoNestedParamsTranspiler } from '@imt-web-zone/core/util-transloco';
import { provideTranslocoMessageformat } from '@jsverse/transloco-messageformat';

export const provideMockTransloco = (defaultLang = 'en') => [
	translocoNestedParamsTranspiler,
	provideTranslocoMessageformat(),
	provideTranslocoMissingHandler(DefaultMissingHandler),
	provideTranslocoInterceptor(DefaultInterceptor),
	provideTranslocoFallbackStrategy(DefaultFallbackStrategy),
	provideTranslocoConfig({
		defaultLang,
		prodMode: false,
		fallbackLang: defaultLang,
		availableLangs: [defaultLang],
		reRenderOnLangChange: true,
	}),
];

export const provideMockTranslocoScope = (scope: string, componentDir: string, defaultLang = 'en'): Provider => ({
	provide: TRANSLOCO_SCOPE,
	multi: true,
	useFactory: () => {
		return {
			scope,
			loader: {
				[defaultLang]: () =>
					import(`../../../${componentDir}/component-tests-scenarios/src/+i18n/${defaultLang}.json`),
			},
		};
	},
});
