import { APP_INITIALIZER, inject, InjectionToken, Provider, Type } from '@angular/core';
import { debugLogger } from './debug-logger';
import { Router } from '@angular/router';
const debug = debugLogger.create('appBootstrap');

const BASE_DEPENDENCY = new InjectionToken<Array<() => Promise<void>>>('BASE_DEPENDENCY');
const SERVICE_INIT = new InjectionToken<Array<() => Promise<void>>>('SERVICE_INIT');

type ConfigFactory<T extends ServiceInit, S extends Array<any>> = (
	deps1?: InstanceType<S[0]>,
	deps2?: InstanceType<S[1]>,
	deps3?: InstanceType<S[2]>,
	deps4?: InstanceType<S[3]>,
	deps5?: InstanceType<S[4]>,
) => Promise<Parameters<T['initialize']>[0]>;

export function initializeApp() {
	return {
		provide: APP_INITIALIZER,
		useFactory: initializeAppProvider,
		multi: true,
	};
}

/**
 * application bootstrap which initializes all BASE_DEPENDENCY and SERVICE_INIT tokens
 *
 * BASE_DEPENDENCY tokens are resolved first and sequentially
 *
 * SERVICE_INIT tokens are resolved in parallel and after the BASE_DEPENDENCY
 *
 */
function initializeAppProvider() {
	const baseDeps = inject(BASE_DEPENDENCY, { optional: true }) || [];
	const serviceDeps = inject(SERVICE_INIT, { optional: true }) || [];
	const router = inject(Router);

	/**
	 * initialize service, ignore error if bootstrap already failed
	 */
	const init = (service: () => Promise<any>, failed: boolean) => {
		return service().catch((e: Error) => {
			if (!failed) {
				console.error(e);
			}
		});
	};

	return async () => {
		let failed = false;

		// initialize configsInitiators sequentially
		for (const depInit of baseDeps) {
			try {
				await depInit();
			} catch (e) {
				failed = true;
				console.error('BASE_DEPENDENCY init failed', e);
				break;
			}
		}

		// initialize servicesInitiators
		await Promise.all(serviceDeps.map((service) => init(service, failed)));
		if (failed) {
			router.navigate(['/error']);
		}
	};
}

/**
 *
 * Dependencies which are resolved first and sequentially in the APP_INITIALIZER
 *
 */
export function baseDependencyInit<T extends Type<ServiceInit>, S extends Array<any>>(
	Service: T,
	configFactory?: ConfigFactory<InstanceType<T>, S>,
	...deps: S
) {
	return providerFactory<T, S>(BASE_DEPENDENCY, Service, configFactory, deps);
}

/**
 *
 * Dependencies which are resolved in parallel and after the base dependencies in the APP_INITIALIZER
 *
 */
export function serviceInit<T extends Type<ServiceInit>, S extends Array<any>>(
	Service: T,
	configFactory?: ConfigFactory<InstanceType<T>, S>,
	...deps: S
) {
	return providerFactory(SERVICE_INIT, Service, configFactory, deps);
}

function providerFactory<T extends Type<ServiceInit> & StaticProps, S extends Array<any>>(
	TOKEN: typeof BASE_DEPENDENCY | typeof SERVICE_INIT,
	Service: T,
	configFactory?: ConfigFactory<InstanceType<T>, S>,
	deps?: S,
) {
	const providers = Service.providers ? [Service.providers] : [];
	return [
		...providers,
		{
			provide: TOKEN,
			useFactory: (...args: Array<any>) => {
				const service: InstanceType<T> = inject(Service);
				return async () => {
					const config = configFactory ? await configFactory(...args) : undefined;
					await service.initialize(config);
					debug(
						`${TOKEN.toString().replace('InjectionToken ', '')}: "${
							service.constructor.name
						}" initialized.`,
					);
				};
			},
			multi: true,
			deps,
		},
	];
}

interface StaticProps {
	providers?: Array<Provider>;
}

export interface ServiceInit {
	initialize(...args: Array<any>): Promise<void>;

	// implements StaticProps
}
