import { inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';

import { distinctUntilChanged, filter, map } from 'rxjs/operators';

// To be PCI compliant, you must load Stripe.js directly from https://js.stripe.com. You cannot include it in a bundle or host it yourself. This package wraps the global Stripe function provided by the Stripe.js script as an ES module.
// https://github.com/stripe/stripe-js
import { Stripe } from '@stripe/stripe-js';
import { ImtUiAssetsInjectorResult, BodyTagDef, ImtUiAssetsInjector } from '@imt-web-zone/shared/util-assets-injector';
import { ApiConfigFacade } from '@imt-web-zone/zone/state-api-config';

// https://stripe.com/docs/js
const STRIPE_JS_SRC = `https://js.stripe.com/v3/`;

@Injectable({ providedIn: 'root' })
export class StripeService {
	private renderer2: Renderer2;
	private els: Array<ImtUiAssetsInjectorResult>;
	private stripe: Stripe;
	private apiConfigFacade = inject(ApiConfigFacade);

	public get instance(): Stripe {
		return this.stripe;
	}

	constructor(private assetInjector: ImtUiAssetsInjector, rendererFactory: RendererFactory2) {
		this.renderer2 = rendererFactory.createRenderer(null, null);

		// Run initialization once service is instantiated.
		this.init();
	}

	/**
	 * Gets mandatory data for initialization and runs initialization once data is available.
	 */
	private init(): void {
		this.apiConfigFacade.tokens$
			.pipe(
				filter((tokens) => !!tokens?.stripePublishableKey),
				map((tokens) => tokens.stripePublishableKey),
				distinctUntilChanged(),
			)
			.subscribe(this.initStripe.bind(this));
	}

	/**
	 * Initializes Stripe.js
	 * https://stripe.com/docs/js/initializing
	 */
	private async initStripe(publishableKey: string): Promise<void> {
		if (!publishableKey) {
			return;
		}

		try {
			if (this.els) {
				this.els.forEach((el) => el.el.remove());
			}

			const stripeScript: BodyTagDef = {
				tag: 'script',
				position: 'head',
				src: STRIPE_JS_SRC,
				attributes: [{ attr: 'async' }],
				onLoad: () => (this.stripe = window['Stripe']?.(publishableKey)),
			};

			this.els = await this.assetInjector.inject(this.renderer2, stripeScript);
		} catch (error) {
			console.error('Error appending Stripe.js');
			console.error(error);
		}
	}
}
