import { inject, Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { combineLatest, interval, Observable, of, Subscription } from 'rxjs';
import { debounceTime, mergeMap, startWith, takeWhile, tap } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { SessionChecks, SessionChecksService } from './session-checks.service';
import { ServiceInit } from '@imt-web-zone/core/util-core';

interface HqSessionConfig {
	userId$: Observable<string>;
	hqDomain$: Observable<string>;
}

@Injectable({ providedIn: 'root' })
export class HqSessionService implements ServiceInit {
	private usersMeInterval = 300000; // 5 minutes
	private document!: Document;
	private renderer2: Renderer2;
	private hqDomain: undefined | string;
	private tabActive: boolean;
	private usersMe: SessionChecks = {
		timer: null,
		callBackFn: () => {
			this.usersMe.subscription = this.startInterval(this.usersMeInterval);
		},
	};
	private sessionChecksService = inject(SessionChecksService);

	constructor(rendererFactory: RendererFactory2, @Inject(DOCUMENT) document?: any) {
		this.document = document as Document;
		this.tabActive = !document.hidden;
		this.renderer2 = rendererFactory.createRenderer(null, null);

		document.addEventListener('visibilitychange', () => {
			this.tabActive = !document.hidden;
			if (this.tabActive) {
				this.getStorageAndStartInterval(true);
			} else {
				this.usersMe.subscription?.unsubscribe();
			}
		});
	}

	public async initialize(args: HqSessionConfig): Promise<void> {
		if (!args) {
			return;
		}
		const { userId$, hqDomain$ } = args;
		combineLatest([userId$, hqDomain$])
			.pipe(debounceTime(3000))
			.subscribe(([userId, hqDomain]) => {
				this.resolveHQSessionUpdate(userId, hqDomain);
			});
	}

	private async resolveHQSessionUpdate(userId: string, hqDomain: string) {
		if (userId && hqDomain) {
			this.hqDomain = hqDomain;
			this.getStorageAndStartInterval(true);
		} else {
			this.usersMe.subscription?.unsubscribe();
		}
	}

	private createAndDestroyIFrame() {
		const iFrameId = 'hq-session-iframe';
		const exists = this.document.getElementById(iFrameId);

		if (exists) {
			this.renderer2.removeChild(this.document.body, exists);
			return of(false);
		}

		this.sessionChecksService.getOrSetLocalStorage('usersMe');
		const el = this.renderer2.createElement('iframe');
		el.setAttribute('id', iFrameId);
		el.setAttribute('src', `https://${this.hqDomain}/api/users/me`);
		el.setAttribute('style', `display:none;width:0;height:0;`);

		this.renderer2.appendChild(this.document.body, el);

		setTimeout(() => {
			this.renderer2.removeChild(this.document.body, el);
		}, 2500);

		this.sessionChecksService.getOrSetLocalStorage('usersMe');
		return of(true);
	}

	private getStorageAndStartInterval(checkForLastRequestTime?: boolean) {
		this.sessionChecksService.getStorageAndStartInterval(
			this.usersMe,
			'usersMe',
			checkForLastRequestTime,
			this.usersMeInterval,
		);
	}

	private startInterval(sessionCheckInterval: number): Subscription {
		const interval$ = interval(sessionCheckInterval).pipe(
			startWith(0),
			takeWhile(() => this.tabActive),
			tap(() => {
				this.sessionChecksService.getOrSetLocalStorage('usersMe');
			}),
			mergeMap(() => {
				return this.createAndDestroyIFrame();
			}),
			tap((iframeExist) => {
				if (!iframeExist) {
					this.sessionChecksService.getOrSetLocalStorage('usersMe');
				}
			}),
		);
		return interval$.subscribe();
	}
}
