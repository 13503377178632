import { ChangeDetectionStrategy, Component, DestroyRef, inject, Inject, OnInit, Renderer2 } from '@angular/core';
import { TakeUntilDestroy } from '@imt-web-zone/shared/util';
import { Utils } from '@imt-web-zone/shared/data-access';
import { CommonSelectors } from '@imt-web-zone/zone/state-common';
import {
	CanduService,
	RudderStackService,
	UserFlowService,
	StripeService,
	AppStatusService,
} from '@imt-web-zone/zone/data-access';
import { Getter, viewSelectSnapshot } from '@imt-web-zone/shared/util-store';
import { TranslocoService } from '@jsverse/transloco';
import { NavigationEnd, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { IMT_EVENTS, ImtEvents, ImtEventsEnum } from '@imt-web-zone/shared/core';
import { ResolveAdminUrlPipe } from '@imt-web-zone/zone/feature-restricted-shared-module';
import { distinctUntilChanged, filter, map, skip, take } from 'rxjs';
import { GrowthbookService } from '@imt-web-zone/shared/data-access-growthbook';
import { OrganizationsFacade } from '@imt-web-zone/zone/data-access-state/organizations';
import { TeamsFacade } from '@imt-web-zone/zone/state-teams';
import { ApiConfigFacade } from '@imt-web-zone/zone/state-api-config';
import { SelectRxSnapshot } from '@imt-web-zone/core/util-state-facade';
import {
	UI_TOAST_MESSAGE_DEFAULT_OPTIONS,
	UI_TOAST_MESSAGE_PERMANENT,
	UiToastMessageRef,
	UiToastMessageService,
	UiToastMessageType,
} from '@imt-web-zone/make-design-system/ui-toast-message';
import { AuthFacade } from '@imt-web-zone/zone/state-auth';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LocalstorageService, ZoneUserCookiesService } from '@imt-web-zone/zone/data-access-storages';
import { INTEGROMAT_ROOT_ELEMENT } from '../constants';

@TakeUntilDestroy()
@Component({
	selector: INTEGROMAT_ROOT_ELEMENT,
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
	private offlineToastStatus: UiToastMessageRef;
	private sessionChangedToastRef: UiToastMessageRef;
	private apiConfigFacade = inject(ApiConfigFacade);
	private toastOptions = inject(UI_TOAST_MESSAGE_DEFAULT_OPTIONS);

	@SelectRxSnapshot() public apiConfigData = this.apiConfigFacade.configRxSnapshot;
	public teamId = inject(TeamsFacade).activeIdSnapshot;
	@Getter() public showLogoIndicator = viewSelectSnapshot(this, CommonSelectors.showLogoIndicator);

	private organizationsFacade = inject(OrganizationsFacade);
	private teamsFacade = inject(TeamsFacade);
	private authFacade = inject(AuthFacade);
	private destroyRef = inject(DestroyRef);

	constructor(
		private appStatusService: AppStatusService,
		private toastService: UiToastMessageService,
		private transloco: TranslocoService,
		private router: Router,
		private localstorageService: LocalstorageService,
		private cookiesService: ZoneUserCookiesService,
		private renderer: Renderer2,
		@Inject(DOCUMENT) private document: Document,
		@Inject(IMT_EVENTS) private imtEvents: ImtEvents,
		userflow: UserFlowService,
		rudderstack: RudderStackService,
		candu: CanduService,
		stripe: StripeService,
		// todo initialize in core-module
		private gbService: GrowthbookService,
	) {
		this.renderer.addClass(this.document.querySelector('html'), 'imt');

		this.appStatusService.isOffline$
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(this.toggleOfflineNotification.bind(this));

		this.router.events.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((event) => {
			if (event instanceof NavigationEnd) {
				window.dispatchEvent(new Event('ngredirect'));
			}
		});

		this.inspectorGlobalSubscriptions();

		this.localstorageService.userIdChanged$
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(this.sessionChanged.bind(this));

		const hasTestCookie = this.cookiesService.readCookie('imt_test_environment');

		this.gbService.contextChanged$.pipe(take(1)).subscribe(() => {
			// This condition is checked only once after user data arrives.
			// It is part of AB experiment, so we dont want to check that again.
			if (!hasTestCookie) {
				if (!this.gbService.isOn('candu-disabled')) {
					candu.init(
						this.authFacade.userId$,
						this.apiConfigFacade.config$.pipe(map((apiConfigData) => apiConfigData?.tokens?.candu)),
						{
							createNewScenario: () => {
								const teamId = this.teamsFacade.activeIdSnapshot;
								this.router.navigate([teamId, 'scenarios', 'add']);
							},
							goToCtaPage: () => {
								const orgId = this.organizationsFacade.activeIdSnapshot;
								this.router.navigate(['organization', orgId, 'subscription']);
							},
						},
					);
				}
				if (!this.gbService.isOn('userflow-disabled')) {
					userflow.init(
						this.authFacade.userId$,
						this.apiConfigFacade.tokens$.pipe(
							map((tokens) => tokens?.userflow),
							distinctUntilChanged(),
						),
					);
				}
			}
		});
	}

	public ngOnInit() {
		this.setFavicon();

		this.appStatusService.init();
	}

	public inspectorGlobalSubscriptions() {
		// TODO: remove this as part of https://integromat.atlassian.net/browse/CDM-10275
		this.imtEvents
			.on$(ImtEventsEnum.FLASH_SHOW_TOAST)
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((imtEvent) => {
				// if the event is dispatch with this flag, don't show the toast because inspector will display it
				// internally
				if (imtEvent.data.__dominoWrapper) {
					return;
				}
				const toastRef = this.toastService.show({
					...imtEvent.data,
					text: imtEvent.data.text ? this.transloco.translate(imtEvent.data.text) : null,
					type: this.convertFromLegacyToastType(imtEvent.data.type),
					expiration: imtEvent.data.permanent ? UI_TOAST_MESSAGE_PERMANENT : undefined,
				});

				if (typeof imtEvent.data.onShow === 'function') {
					// there's one place in inspector codebase where the toast is closed programatically
					// onShow is used to pass the reference to the angular toast so that Inspector can call close on it
					imtEvent.data.onShow(toastRef);
				}
			});

		this.imtEvents
			.on$(ImtEventsEnum.INSPECTOR_MODE_TRANSITION)
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((imtEventData) => {
				const [prevMode, nextMode] = imtEventData.data.split('->');
				const element = document.querySelector('body');
				this.renderer.removeClass(element, `inspector-${prevMode}`);
				this.renderer.addClass(element, `inspector-${nextMode}`);
			});

		this.imtEvents
			.on$(ImtEventsEnum.REDIRECT)
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe((e) => {
				const resolveAdminUrlPipe = new ResolveAdminUrlPipe(this.router);

				if (e.data.absolute) {
					return this.router.navigateByUrl(resolveAdminUrlPipe.transform(e.data.url));
				}

				const segments = Utils.getUrlSegments(this.router);

				let path: any;
				if (e.data && e.data.url instanceof Array) {
					path = segments ? segments.concat(e.data.url) : e.data.url;
				} else {
					path = e.data.url?.split('/');
				}

				this.router.navigate(resolveAdminUrlPipe.transform(path));
			});
	}

	private convertFromLegacyToastType(type: 'error' | 'success' | 'warning' | 'info') {
		switch (type) {
			case 'error':
				return UiToastMessageType.DANGER;
			case 'success':
				return this.toastOptions.defaultType;
			case 'info':
				return this.toastOptions.defaultType;
			case 'warning':
				return UiToastMessageType.WARNING;
			default: {
				return this.toastOptions.defaultType;
			}
		}
	}

	private setFavicon() {
		const favicon: HTMLLinkElement = document.querySelector('#favicon');
		favicon.href = this.apiConfigFacade.isMaster
			? 'favicon.ico'
			: `https://${this.apiConfigData?.slaveDomains?.cdn}/img/make/favicon.ico`;
	}

	private sessionChanged(userId: string) {
		if (this.router.url === '/login') {
			window.location.reload();
		} else if (!userId) {
			this.authFacade
				.logout$({ sync: true, redirect: true })
				.pipe(takeUntilDestroyed(this.destroyRef))
				.subscribe();
			this.sessionChangedToastRef = this.toastService.show({
				type: UiToastMessageType.WARNING,
				text: this.transloco.translate('session.signedout'),
				expiration: UI_TOAST_MESSAGE_PERMANENT,
			});
		}

		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd && !!this.sessionChangedToastRef),
				skip(1),
				takeUntilDestroyed(this.destroyRef),
			)
			.subscribe((event) => {
				if (this.sessionChangedToastRef) {
					this.sessionChangedToastRef.close();
					this.sessionChangedToastRef = null;
				}
			});
	}

	private toggleOfflineNotification(isOffline: boolean) {
		if (isOffline) {
			this.offlineToastStatus = this.toastService.showDanger({
				title: this.transloco.translate('offline.noconnection'),
				text: this.transloco.translate('offline.description'),
				expiration: UI_TOAST_MESSAGE_PERMANENT,
			});
		} else if (this.offlineToastStatus) {
			this.offlineToastStatus.close();
		}
	}
}
