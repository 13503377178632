import { inject, Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { APP_NAME, AppNames } from '@imt-web-zone/shared/core';
import { ImtUiAssetsInjector } from '@imt-web-zone/shared/util-assets-injector';
import { environment } from '@imt-web-zone/shared/environments';
import { WebStreamerService } from '@imt-web-zone/shared/data-access';
import { GrowthbookService } from '@imt-web-zone/shared/data-access-growthbook';
import { BehaviorSubject } from 'rxjs';
import { DatadogService } from '@imt-web-zone/core/data-access-datadog';
import { ZoneAssetsDomain, ZoneAssetsService } from '@imt-web-zone/zone/util-zone-assets';
import { ApiConfigFacade } from '@imt-web-zone/zone/state-api-config';
import { UtilURL } from '@imt-web-zone/shared/util';

declare global {
	interface Window {
		IMT_INSPECTOR: {
			buildTimestamp: string;
			version: string;
			env: string;
		};
	}
}

/**  
Env Variables Map

NOTE:
// `{{version}}` is replaced on the FE level (via `AssetsService`/`ZoneAssetsService`)

Local Environment
--------------------------------
- Master
INSPECTOR_CDN_URL=/zone-assets-server/inspector/{{version}}

- Slave
INSPECTOR_CDN_URL=/zone-assets-server/inspector/{{version}}

Live environments (infra team)
--------------------------------------------
- Master
INSPECTOR_CDN_URL=/cdn-proxy/files/inspector/branch/master (development)
INSPECTOR_CDN_URL=/cdn-proxy/files/inspector/latest (staging)
INSPECTOR_CDN_URL=/zone-assets-server/inspector/{{version}} (release)
INSPECTOR_CDN_URL=/zone-assets-server/inspector/{{version}} (production)

- Slave
INSPECTOR_CDN_URL=https://cdn.integromat.dev/files/inspector/branch/master (development)
INSPECTOR_CDN_URL=https://cdn.workapp.dev/files/inspector/latest (staging)
INSPECTOR_CDN_URL=https://cdn.hqrelease.net/{{version}} (release)
INSPECTOR_CDN_URL=https://cdn.make.com/{{version}} (production)
*/

@Injectable({
	providedIn: 'root',
})
export class InspectorLoaderService {
	private renderer2: Renderer2;
	private cacheBustingParam = `?_=${process.env['BUILD_TIMESTAMP']}`;
	private inspectorLoadedSubject = new BehaviorSubject(false);
	private zoneAssetsService = inject(ZoneAssetsService);
	private apiConfigFacade = inject(ApiConfigFacade);

	constructor(
		private assetsInjector: ImtUiAssetsInjector,
		@Inject(APP_NAME) private appName: AppNames,
		private renderer: RendererFactory2,
		private webStreamerService: WebStreamerService,
		private growthbook: GrowthbookService,
		private dd: DatadogService,
	) {
		this.renderer2 = this.renderer.createRenderer(null, null);
	}

	public get inspectorLoaded$() {
		return this.inspectorLoadedSubject.asObservable();
	}

	public async loadInspector(inspectorUrl: string, inspectorFallbackVersion: string) {
		this.zoneAssetsService.updateAssetsDomains({
			[ZoneAssetsDomain.Inspector]: this.resolveInspectorUrl(inspectorUrl, inspectorFallbackVersion),
		});

		await this.injectDecaffeinatedInspector();
		this.inspectorLoadedSubject.next(true);
		if (window.IMT_INSPECTOR) {
			this.dd.setMetadata('inspector-version', window.IMT_INSPECTOR.version);
			this.dd.setMetadata('inspector-build-timestamp', window.IMT_INSPECTOR.buildTimestamp);
			this.dd.setMetadata('inspector-env', window.IMT_INSPECTOR.env);
		}
	}

	private async injectDecaffeinatedInspector() {
		let src = this.zoneAssetsService.assetPath({
			relativePath: UtilURL.urlJoin('/inspector.umd.js', this.cacheBustingParam),
			domain: ZoneAssetsDomain.Inspector,
		});

		if (!(await this.injectLocalInspector())) {
			// `src` might be null if we introduce new zone mode, this prevents crash in that case
			src = src
				? src
				: this.zoneAssetsService.zoneAssetPath(
						UtilURL.urlJoin('/inspector/inspector.umd.js', this.cacheBustingParam),
				  );
			const [{ result }] = await this.assetsInjector.inject(this.renderer2, {
				tag: 'script',
				src,
			});

			// fallback to node_modules if loading fails
			if (result !== 'success') {
				src = this.zoneAssetsService.zoneAssetPath(
					UtilURL.urlJoin('/inspector/inspector.umd.js', this.cacheBustingParam),
				);
				await this.assetsInjector.inject(this.renderer2, {
					tag: 'script',
					src,
				});
			}

			const styles = src.replace('inspector.umd.js', 'style.css');
			await this.assetsInjector.inject(this.renderer2, {
				tag: 'link',
				src: styles,
				type: 'css',
			});
		}

		await this.assetsInjector.inject(this.renderer2, {
			tag: 'script',
			src: this.zoneAssetsService.zoneAssetPath(
				UtilURL.urlJoin('/vendor/iml/iml.min.js', this.cacheBustingParam),
			),
		});

		if (this.appName !== AppNames.ZONE_HQ_ADMIN) {
			await this.injectStreamer();
		}
	}

	private async injectLocalInspector() {
		// for local environment, try to load inspector from imt-inspector dev server
		// if that fails, fallback to installed inspector from node_modules
		let localLoaded = false;
		if (environment.env === 'local') {
			const [{ result }] = await this.assetsInjector.inject(this.renderer2, {
				tag: 'script',
				src: '/local-inspector/lib/index.ts',
				type: 'module',
			});

			localLoaded = result === 'success';
		}
		return localLoaded;
	}

	private async injectStreamer() {
		const streamerUrl = environment.streamer?.baseUrl ?? '';

		await this.assetsInjector.inject(this.renderer2, {
			tag: 'script',
			src: UtilURL.urlJoin(streamerUrl, '/live/socket.io.js', this.cacheBustingParam),
		});
		await this.assetsInjector.inject(this.renderer2, {
			tag: 'script',
			src: UtilURL.urlJoin(streamerUrl, '/lib/web_streamer_lib.min.js', this.cacheBustingParam),
		});
		this.webStreamerService.initialize();
	}

	/**
	 * Resolves correct URL from which Inspector should be loaded.
	 *
	 * Few point that are taken into account:
	 * - If Zone CDN (`zone-versions`) feature is enabled.
	 * - If `inspector-cdn` feature is set and in which mode (master/slave).
	 * - Version of the Inspector that may come from various sources.
	 * - Fallback to Zone assets (`/static/inspector`).
	 */
	private resolveInspectorUrl(inspectorUrl: string, inspectorFallbackVersion: string): string {
		// Get the Zone assets version (means that Zone CDN feature is enabled).
		const { zone: zoneVersion } = this.growthbook.getFeatureValue<{ zone?: string }>('zone-versions', {});

		// Contains string like: `files/inspector/v0.3.7`.
		const cdnFeatureFlag =
			(this.growthbook.getFeatureValue<string>('inspector-cdn', null) || localStorage.getItem('inspector-cdn')) ??
			null;

		// If Zone CDN feature is available (`zoneVersion` exists), get and use inspector version
		// (either from feature flag or `package.json` as fallback) to resolve inspector URL.
		if (zoneVersion) {
			return ZoneAssetsService.replaceVersionInURL(inspectorUrl, cdnFeatureFlag || inspectorFallbackVersion);
		}

		// TODO: Delete following block when Zone CDN is fully enabled
		// ===========================================================
		// Keep following old logic for concatenating URLs for sake of backward compatibility.
		const apiConfig = this.apiConfigFacade.configSnapshot;
		const inspectorPath =
			cdnFeatureFlag ||
			(!inspectorUrl && !(apiConfig.domains['*'] === '*.integromat.local')
				? environment.appConfig.inspectorCdn
				: null);

		if (inspectorPath && typeof inspectorPath === 'string') {
			const isMasterMode = this.apiConfigFacade.isMaster;
			const isSlaveMode = this.apiConfigFacade.isSlave;

			if (isSlaveMode) {
				return UtilURL.urlJoin('https://', this.apiConfigFacade.configSnapshot.slaveDomains.cdn, inspectorPath);
			}

			if (isMasterMode) {
				return UtilURL.urlJoin('/cdn-proxy/', inspectorPath);
			}

			// Fallback to Zone static assets, when `mode` is not available.
			// (HQ Admin does not have `mode` in the configuration)
			return this.zoneAssetsService.zoneAssetPath('/inspector');
		}
		// If inspector URL is not defined, use Zone static assets path as a fallback.
		else if (!inspectorUrl) {
			return this.zoneAssetsService.zoneAssetPath('/inspector');
		}
		// ===========================================================

		return ZoneAssetsService.replaceVersionInURL(inspectorUrl, inspectorFallbackVersion);
	}
}
