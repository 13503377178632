export enum CodeownersTeamsEnum {
	ATLAS = 'atlas',
	NEXUS = 'nexus',
	APPS_PLATFORM = 'apps-platform',
	GROWTH_ENG = 'growth-engineering',
	MEDUSA = 'medusa',
	POSEIDON = 'poseidon',
	TYPHON = 'typhon',
	UI_PLATFORM = 'ui-platform',
}

type CodeownersTeamsInterface = Record<
	CodeownersTeamsEnum,
	{
		name: string;
		contributors: Array<string>;
	}
>;

export const CodeownersTeams: CodeownersTeamsInterface = {
	atlas: {
		name: 'Atlas',
		contributors: ['@spiska', '@orromis', '@petrmm'],
	},

	nexus: {
		name: 'Nexus',
		contributors: ['@drewenco', '@illia-make', '@jindrichmaca', '@skvelymake', '@StefZadreva'],
	},

	'apps-platform': {
		name: 'Apps Platform',
		contributors: ['@mikulassychra', '@ma-zal'],
	},

	'growth-engineering': {
		name: 'Growth Engineering',
		contributors: ['@vilemraska', '@jakubstokcelonis'],
	},

	medusa: {
		name: 'Medusa',
		contributors: ['@mbajtalon'],
	},

	poseidon: {
		name: 'Poseidon',
		contributors: ['@mihajakse', '@jankulhavy'],
	},

	typhon: {
		name: 'Typhon',
		contributors: ['@kirillmetrik', '@ladislavpr', '@sitterlukas'],
	},

	'ui-platform': {
		name: 'UI platform',
		contributors: ['@vojtesaak', '@ivan-rozhon', '@martinsik', '@ondrejpesat'],
	},
};
