import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { NgClass, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { UiStaticMessageButtonPosition, UiStaticMessageType } from './ui-static-message.interface';
import {
	UiNotificationButtonType,
	UiNotificationButtonComponent,
} from '@imt-web-zone/make-design-system/ui-notification-button';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

function transformType(type: string) {
	const allowedTypes: string[] = [
		UiStaticMessageType.INFO,
		UiStaticMessageType.WARNING,
		UiStaticMessageType.BRAND,
		UiStaticMessageType.SUCCESS,
		UiStaticMessageType.DANGER,
	];
	if (type && !allowedTypes.includes(type)) {
		throw `Unknown static message type "${type}".`;
	}
	return type;
}

function transformPosition(position: string) {
	const allowedPositions: string[] = [UiStaticMessageButtonPosition.BOTTOM, UiStaticMessageButtonPosition.RIGHT];
	if (position && !allowedPositions.includes(position)) {
		throw `Unknown static message button position "${position}".`;
	}
	return position;
}

@Component({
	selector: 'dmo-static-message',
	standalone: true,
	imports: [NgIf, NgFor, NgClass, UiNotificationButtonComponent, NgTemplateOutlet],
	templateUrl: './ui-static-message.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiStaticMessageComponent {
	/**
	 * @ignore
	 */
	public readonly UiStaticMessageType = UiStaticMessageType;
	/**
	 * @ignore
	 */
	public readonly UiStaticMessageButtonPosition = UiStaticMessageButtonPosition;

	@HostBinding('class') public get hostDefaultClasses() {
		const hostClasses = [
			'dmo-rounded dmo-flex dmo-flex-row dmo-border dmo-p-16 dmo-pl-12 dmo-gap-8 dmo-font-notosans dmo-text-xs',
		];

		switch (this.type) {
			case UiStaticMessageType.INFO:
				hostClasses.push('dmo-bg-static-info-subdued', 'dmo-border-info', 'dmo-text-info');
				break;
			case UiStaticMessageType.SUCCESS:
				hostClasses.push('dmo-bg-static-success-subdued', 'dmo-border-success', 'dmo-text-success');
				break;
			case UiStaticMessageType.WARNING:
				hostClasses.push('dmo-bg-static-warning-subdued', 'dmo-border-warning', 'dmo-text-warning');
				break;
			case UiStaticMessageType.DANGER:
				hostClasses.push('dmo-bg-static-danger-subdued', 'dmo-border-danger', 'dmo-text-danger');
				break;
			case UiStaticMessageType.BRAND:
				hostClasses.push('dmo-bg-static-brand-subdued', 'dmo-border-brand', 'dmo-text-brand');
				break;
		}

		if (!this.isVisible) {
			hostClasses.push('dmo-hidden');
		}

		return hostClasses.join(' ');
	}

	/**
	 * Static message type.
	 */
	@HostBinding('attr.aria-label')
	@Input({ transform: transformType })
	public type: UiStaticMessageType | string = UiStaticMessageType.INFO;
	/**
	 * Optional static message title.
	 */
	@Input() public title?: string;
	/**
	 * Button position. On small screens, even when set to `UiStaticMessageButtonPosition.RIGHT`, the button will
	 * appear in the "bellow" position anyway.
	 */
	@Input({ transform: transformPosition }) public buttonPosition: UiStaticMessageButtonPosition | string =
		UiStaticMessageButtonPosition.RIGHT;
	/**
	 * Button text. The static message button won't show until it has a text set.
	 */
	@Input() public buttonLabel?: string;
	/**
	 * Show close icon in the top right corner.
	 */
	@Input({ transform: coerceBooleanProperty }) public showCloseButton = false;
	/**
	 * Optional list of bullet points related to this alert displayed at the bottom of the message.
	 * It's recommended to use this property instead of using `<ul>` tag in message's template for better consistency.
	 */
	@Input() public bulletPoints?: string[];

	/**
	 * Emits when the cross icon in the top right corner is clicked
	 */
	@Output() public closed = new EventEmitter<void>();
	/**
	 * Emits when the static message button is clocked.
	 */
	@Output() public buttonClicked = new EventEmitter<void>();

	private isVisible = true;

	public get buttonType() {
		// Right now both enums have the same values
		return this.type as any as UiNotificationButtonType;
	}

	/**
	 * Hide the static message. This only hides the static message, it won't remove it from DOM.
	 */
	public close() {
		this.isVisible = false;
		this.closed.emit();
	}
}
