import { CollectionStateModel, getInitialCollectionState, SortingOptions } from '@imt-web-zone/shared/util-store';
import { Alias, Field, ModelAdapter, Type } from '@imt-web-zone/core/util-state-model-adapter';
import { TeamApiModel } from './teams.interface';

export const teamsSortConfig: SortingOptions<TeamModel> = {
	sortByKey: 'id',
	sortCondition: (a, b) => {
		return +b.id - +a.id;
	},
};

@ModelAdapter()
export class TeamModel<T extends TeamApiModel = TeamApiModel> {
	@Type(Number) public id!: string;
	@Field public name!: string;
	@Type(Number) public organizationId?: string;
	@Field public country?: string;
	@Field public timezone?: string;
	@Field public license?: {
		apps: string;
		dslimit: number;
		dsslimit: number;
		fslimit: number;
		fulltext: boolean;
		interval: number;
		iolimit: number;
		operations: number;
		transfer: number;
		users: number;
	};
	@Alias<T>('deleted') public isDeleted?: boolean;
	@Field public scenarioDrafts?: boolean;

	constructor(inputData: Partial<T>, adapt: boolean) {
		//
	}
}

export function getInitialTeamsStateModel(): CollectionStateModel<TeamModel> {
	return getInitialCollectionState<TeamModel>();
}
