import { State, Action, StateContext, createSelector } from '@ngxs/store';
import { LoadEnum, loadEnum } from './enums.actions';
import { EnumNames, EnumsModel, EnumEntities, EnumsModelMap } from './enums.model';
import { EnumsService } from './enums.service';
import { Injectable } from '@angular/core';
import { EnumsResponses, TimezoneEnumApiModel } from './enums.interface';
import * as moment from 'moment';
import 'moment-timezone';
import { take } from 'rxjs/operators';
import { ENUMS_STATE_TOKEN } from './enums.symbols';

@State({
	name: ENUMS_STATE_TOKEN,
	defaults: {} as EnumsModel,
})
@Injectable()
export class EnumsState {
	public static getEnum<T extends EnumNames>(enumName: T) {
		return createSelector([EnumsState], (state: EnumsModel) => {
			return state ? state[enumName] : null;
		});
	}

	public static getEnumMap<T extends EnumNames>(enumName: T) {
		return createSelector([EnumsState], (state: EnumsModel): EnumsModel[T]['entities'] => {
			return state && state[enumName] && state[enumName].entities ? state[enumName].entities : {};
		});
	}

	public static getEnumValue<T extends EnumNames>(enumName: T, key: string) {
		return createSelector([EnumsState], (state: EnumsModel): EnumsModel[T]['entities']['key'] => {
			return (state && state[enumName] && state[enumName].entities ? state[enumName].entities : ({} as unknown))[
				key
			];
		});
	}

	public static getEnumArray<T extends EnumNames>(enumName: T) {
		return createSelector([EnumsState], (state: EnumsModel): Array<EnumsModelMap[T]> => {
			return state && state[enumName] && state[enumName].entities
				? state[enumName].ids.map((id) => state[enumName].entities[id] as EnumsModelMap[T])
				: [];
		});
	}

	constructor(private enumsService: EnumsService) {}

	@Action(loadEnum)
	public loadEnum(ctx: StateContext<EnumsModel>, action: LoadEnum) {
		const snakeCaseEnumName = action.enumName.replace(/[\w]([A-Z])/g, (m) => m[0] + '-' + m[1]).toLowerCase();
		return this.enumsService
			.loadEnumValues$(snakeCaseEnumName)
			.pipe(take(1))
			.subscribe((res) => this.loadEnumSuccess(ctx, res, action.enumName, action.enumKey));
	}

	public loadEnumSuccess(ctx: StateContext<EnumsModel>, res: EnumsResponses, enumName: EnumNames, enumKey: string) {
		// cast payload to Array<any> to avoid https://github.com/microsoft/TypeScript/issues/33591
		const updatedEnums = (res[enumName] as Array<any>).reduce(
			(result, item) => {
				result.entities[item[enumKey]] = this.parseEnumItem(item, enumName, enumKey);
				result.ids.push(item[enumKey]);
				return result;
			},
			{ entities: {}, ids: [] } as EnumEntities<any>,
		);

		ctx.setState({
			...ctx.getState(),
			[enumName]: updatedEnums,
		});
	}

	/**
	 * Parses enum item into store model.
	 */
	private parseEnumItem(item: any, enumName: EnumNames, enumKey: string) {
		if (enumName === EnumNames.TIMEZONES) {
			const tzCode = (item as TimezoneEnumApiModel).code;
			// calculate timezone UTC offset and convert it into the format that Angular understands
			const offsetMinutes = moment.tz(tzCode).utcOffset();
			const offset = moment.utc().startOf('day').add(Math.abs(offsetMinutes), 'minutes').format('HH:mm');
			return {
				...item,
				id: item.id?.toString(),
				offset: offsetMinutes < 0 ? `-${offset}` : `+${offset}`,
			};
		} else {
			return {
				...item,
				[enumKey]: item[enumKey]?.toString(),
			};
		}
	}
}
