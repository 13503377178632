import { inject, Injectable, Injector, RendererFactory2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
	ReplaySubject,
	debounceTime,
	concatMap,
	take,
	tap,
	from,
	Observable,
	mergeMap,
	filter,
	map,
	of,
	forkJoin,
} from 'rxjs';
import { createSelector, Store } from '@ngxs/store';
import { environment } from '@imt-web-zone/shared/environments';
import { IMT_APP_INITIALIZER_SELECTORS, IS_CUSTOM_ELEMENT } from '@imt-web-zone/shared/core';
import {
	LicenseParameters,
	OrganizationModel,
	TimezoneEnumApiModel,
	TimezonesEnumModel,
} from '@imt-web-zone/shared/data-access';
import { updateCommonState } from '@imt-web-zone/zone/state-common';
import { InspectorLoaderService } from './inspector-loader.service';
import { debugLogger, ServiceInit } from '@imt-web-zone/core/util-core';
import { FormanLoaderService } from '@imt-web-zone/shared/data-access-forman';
import { FormulaInspectorWrapper, ImtUiFormulaTranslateService } from '@imt-web-zone/zone/data-access-formula';
import { ApiConfigData, ApiConfigFacade, ModeEnum } from '@imt-web-zone/zone/state-api-config';
import { AuthStateModel, AuthUserModel } from '@imt-web-zone/zone/state-auth';
import { TeamModel } from '@imt-web-zone/zone/state-teams';
import { ZoneAssetsDomain, ZoneAssetsService } from '@imt-web-zone/zone/util-zone-assets';
import { GrowthbookService } from '@imt-web-zone/shared/data-access-growthbook';

const debug = debugLogger.create('inspector:base-init');

export interface ImtObject {
	apiurl: string;
	streamerurl: string;
	streamerSocketUrlPrefix: string;
	config: any;
	env: string;
	zone: string;
	mode: string;
	hqUrl: string;
	plugins: Array<{ name: string; version: string }>;
	dictionaries: any;
	helpUrl: string;
	features: {
		scenarioInputs: boolean;
		customVariables: boolean;
		onPremAgent: boolean;
		dynamicConnections: boolean;
		personalConnections: boolean;
		sloth: boolean;
		scenarioRelationTree: boolean;
	};
	brand: {
		title?: string;
		theme?: string;
	};
	user: {
		id: number;
		language: string;
		timezone: string;
		locale: string;
		features: AuthUserModel['features'];
	};
	organization: {
		id: number;
		timezone: string;
		license?: LicenseParameters;
	};
	team: {
		id: number;
		scenarioDrafts: boolean;
	};
	remotes: Array<any>;
	static: (url: string) => string;
	base: (url: string) => string;
	standalone: boolean;
	initModules?: Array<string>;
}

declare global {
	interface Window {
		imt?: ImtObject;
	}
}

function getBaseUrl(routerUrl: string) {
	return environment.api.baseUrl + (routerUrl?.startsWith('/admin') ? '/admin' : '');
}

const helpLinkUrl = (apiConfigData: ApiConfigData) => {
	const url =
		apiConfigData?.mode === ModeEnum.SLAVE
			? environment.helpLinkSlave
			: // environment.helpLink can be populated from widget
			  environment.helpLink || apiConfigData?.generalSettings?.helpRoot;
	if (!url) {
		return;
	}
	return url.endsWith('/') ? url : url + '/';
};

@Injectable({
	providedIn: 'root',
})
export class InspectorBaseInit implements ServiceInit {
	private _baseReadySubject$: ReplaySubject<boolean> = new ReplaySubject(1);
	private _formanReadySubject$: ReplaySubject<boolean> = new ReplaySubject(1);
	private depAdded: boolean;
	private config: {
		lang$: Observable<string>;
		user$: Observable<AuthUserModel>;
		// Inspector
		inspectorUrl?: string;
		inspectorFallbackVersion: string;
		// Forman
		formanUrl?: string;
		formanFallbackVersion: string;
	};

	protected rendererFactory: RendererFactory2;
	protected document = inject(DOCUMENT);
	protected initializerSelectors = inject(IMT_APP_INITIALIZER_SELECTORS);
	protected store = inject(Store);
	protected imtUiFormulaTranslateService = inject(ImtUiFormulaTranslateService);
	protected inspectorLoader = inject(InspectorLoaderService);
	protected isCustomElement = inject(IS_CUSTOM_ELEMENT, { optional: true });
	protected formanService = inject(FormanLoaderService);
	protected formulaWrapperService = inject(FormulaInspectorWrapper);

	public get baseReady$() {
		return this._baseReadySubject$;
	}

	public static getHtmlAttributes(selectors: Array<any>, standalone: boolean) {
		return createSelector(
			selectors,
			(
				authState: AuthStateModel,
				team: TeamModel,
				organization: OrganizationModel,
				authUserTimezone: TimezoneEnumApiModel,
				apiConfigData: ApiConfigData,
				timezones: { [key: string]: TimezonesEnumModel },
				routerUrl: string,
			): ImtObject => {
				const authUser = authState.user;
				const orgTimezone =
					organization && organization.timezoneId && timezones
						? timezones[organization.timezoneId]?.code
						: null;

				if (!window.imt) {
					(window.imt as Partial<ImtObject>) = {};
				}

				window.imt.apiurl = typeof routerUrl === 'string' ? getBaseUrl(routerUrl) : getBaseUrl('');
				window.imt.base = (url) => url;
				window.imt.brand = apiConfigData.brand;
				window.imt.config = window.imt.config ?? {};
				window.imt.dictionaries = window.imt.dictionaries ?? {};
				window.imt.env = environment.env;
				window.imt.features = {
					customVariables: !!apiConfigData.generalSettings.variablesEnabled,
					dynamicConnections: !!apiConfigData.generalSettings.dynamicConnectionsEnabled,
					onPremAgent: !!apiConfigData.generalSettings.onPremAgentEnabled,
					personalConnections: !!apiConfigData.generalSettings.personalConnectionsEnabled,
					scenarioInputs: !!apiConfigData.generalSettings.scenarioInputsEnabled,
					sloth: apiConfigData.generalSettings.slothEnabled,
					scenarioRelationTree: !!apiConfigData.generalSettings.scenarioRelationTreeEnabled,
				};
				window.imt.helpUrl = helpLinkUrl(apiConfigData);
				window.imt.hqUrl = apiConfigData.domains.hq;
				window.imt.mode = apiConfigData.mode;
				window.imt.organization = {
					id: organization ? +organization.id : null,
					license: organization?.license,
					timezone: orgTimezone,
				};
				window.imt.plugins = window.imt.plugins ?? [];
				window.imt.remotes = window.imt.remotes ?? [];
				window.imt.standalone = standalone || false;
				window.imt.static = (url) => {
					if (url.startsWith(environment.api.staticUrl)) {
						return url;
					} else if (/^(http(s)?:)?\/\//.test(url)) {
						return url;
					} else {
						return `${environment.api.staticUrl}${url ?? ''}`;
					}
				};
				window.imt.streamerSocketUrlPrefix = environment?.streamer?.socketUrlPrefix;
				window.imt.streamerurl = environment?.streamer?.baseUrl;
				window.imt.team = {
					id: team ? +team.id : null,
					scenarioDrafts: !!team?.scenarioDrafts,
				};
				window.imt.user = {
					id: authUser ? +authUser.id : null,
					timezone: authUserTimezone ? authUserTimezone.code : null,
					locale: authUser ? authUser.locale : null,
					language: authState?.user?.language || apiConfigData?.generalSettings?.defaultLanguage,
					features: authUser?.features,
				};
				window.imt.zone = apiConfigData.zone;
				return window.imt;
			},
		);
	}

	constructor(protected injector: Injector) {
		this.formulaWrapperService = this.injector.get(FormulaInspectorWrapper);
	}

	public async initialize(config: InspectorBaseInit['config']) {
		let formanLoaded = false;
		this.config = config;

		const toFinish = [this._formanReadySubject$.pipe(take(1))];

		this.store
			.select(InspectorBaseInit.getHtmlAttributes(this.initializerSelectors, this.isCustomElement))
			.pipe(
				debounceTime(100),
				concatMap((attrs) => {
					if (!formanLoaded) {
						formanLoaded = true;
						return from(
							this.initializeForman({
								formanUrl: config.formanUrl,
								formanFallbackVersion: config.formanFallbackVersion,
							}),
						).pipe(map(() => attrs));
					}

					return of(attrs);
				}),
				mergeMap((attributes) => this.config.user$.pipe(map((user) => ({ user, attributes })))),
				filter(({ user, attributes }) => attributes.mode === ModeEnum.MASTER || !!user?.id),
				mergeMap(() => this.config.lang$),
				concatMap((lang) =>
					from(this.appendDependencies(!this.depAdded ? false : this.depAdded)).pipe(
						tap(() => (this.depAdded = true)),
					),
				),
			)
			.subscribe();

		await new Promise((resolve) => {
			if (this.isCustomElement) {
				const inspectorLoader$ = this._baseReadySubject$.pipe(
					tap(() => debug('Inspector library loaded.')),
					take(1),
				);
				toFinish.push(inspectorLoader$);
			}

			forkJoin(toFinish).subscribe(() => {
				resolve(void 0);
			});
		});

		debug('window.imt', window.imt);
	}

	/**
	 * Resolves correct URL from which Forman should be loaded.
	 *
	 * Few point that are taken into account:
	 * - If Zone CDN (`zone-versions`) feature is enabled.
	 * - Version of the Forman that may come from various sources (growthbook, package.json).
	 * - Fallback to Zone assets (`/static`).
	 */
	private resolveFormanCdnUrl({
		zoneStaticPath,
		zoneVersion,
		formanUrl,
		formanVersion,
		formanFallbackVersion,
	}: {
		zoneStaticPath: string;
		zoneVersion: string;
		formanUrl: string;
		formanVersion?: string;
		formanFallbackVersion: string;
	}): string {
		// If Zone CDN feature is available (`zoneVersion` exists),
		// get and use forman version to resolve forman URL.
		if (zoneVersion) {
			return ZoneAssetsService.replaceVersionInURL(formanUrl, formanVersion || formanFallbackVersion);
		} // If forman URL is not defined, use Zone static assets path as a fallback.
		else if (!formanUrl) {
			return zoneStaticPath;
		}

		return ZoneAssetsService.replaceVersionInURL(formanUrl, formanFallbackVersion);
	}

	/**
	 * load forman and apply formulaWrapper which contains inspector-specific functionality
	 * @private
	 */
	private async initializeForman({
		formanUrl,
		formanFallbackVersion,
	}: Pick<InspectorBaseInit['config'], 'formanUrl' | 'formanFallbackVersion'>) {
		// todo remove ApiConfig dependency => read value of remoteFormanUrl, remoteFormanAdapterUrl, staticUrl
		//  from InspectorBaseInit['config']
		const zoneAssetsService = this.injector.get(ZoneAssetsService);
		const growthbook = this.injector.get(GrowthbookService);
		const apiConfigFacade = this.injector.get(ApiConfigFacade);
		const apiConfig = apiConfigFacade.configSnapshot;

		// Get the Zone assets versions (Zone and Forman assets)
		const { zone: zoneVersion } = growthbook.getFeatureValue<{ zone?: string }>('zone-versions', {});

		// Resolve assets domain for the Forman
		zoneAssetsService.updateAssetsDomains({
			[ZoneAssetsDomain.Forman]: this.resolveFormanCdnUrl({
				zoneStaticPath: zoneAssetsService.zoneAssetPath('/'),
				zoneVersion,
				formanUrl,
				formanVersion: undefined, // TODO: place for growthbook feature flag controlling Forman version
				formanFallbackVersion,
			}),
		});

		// Static URL point to the Zone`s assets (`/static`) path.
		const staticUrl = zoneAssetsService.zoneAssetPath('/');
		// CDN URL from which Forman will be loaded if available (resolved few lines above).
		const cdnFormanUrl = zoneAssetsService.assetPath({ domain: ZoneAssetsDomain.Forman, relativePath: '/' });

		// Optionally, Forman URL may come from localStorage or API Config
		const remoteFormanUrl = localStorage.remoteFormanUrl || apiConfig?.generalSettings.remoteFormanUrl;
		const remoteFormanAdapterUrl =
			localStorage.remoteFormanAdapterUrl || apiConfig?.generalSettings.remoteFormanAdapterUrl;

		await this.formanService.loadForman({
			staticUrl,
			cdnFormanUrl,
			// If Zone CDN (`zone-versions`) feature is enabled, ignore other ways how to configure forman URL
			...(zoneVersion ? {} : { remoteFormanUrl, remoteFormanAdapterUrl }),
		});

		// Formula adapter for switching
		window['Formula_New'] = window['Formula'];
		// wrap formula with inspector-specific behavior
		this.formulaWrapperService.apply();
		// todo: remove store dependency
		if (window['IMT_FORMAN_VERSION']) {
			this.store.dispatch(updateCommonState({ payload: { forman: window['IMT_FORMAN_VERSION'] } }));
		}

		this.imtUiFormulaTranslateService.initialize(() => apiConfigFacade.configSnapshot?.brand?.theme);
		this._formanReadySubject$.next(true);
		debug('Forman library loaded.');
	}

	private async appendDependencies(appendOnlyLangScripts = false) {
		// since imt-ui-consumptions uses simdom to render the graph, Inspector still has to be loaded when user logs in
		if (!appendOnlyLangScripts) {
			await this.inspectorLoader.loadInspector(this.config.inspectorUrl, this.config.inspectorFallbackVersion);
		}
		this._baseReadySubject$.next(true);
	}
}
